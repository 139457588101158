import { useAppStore } from '@/stores/app'
import { useSessionStore } from '@/stores/session'
import { usePanelsStore } from '@/stores/panels'

import { useValuesStore } from '@/stores/values'

// import { useValuesStore } from '@/stores/values'
import { DEBUG_API, ENV } from '@/env.ts'

import { useAuthStore } from '@/stores/auth'

import type { AuthResponse } from '@/types/api/auth'

import { useUserRecordStore } from '@/stores/userrecord'
import { useUserStateStore } from '@/stores/userstate'

import { KIOE, AEL } from '@/utility/utility'

export function useDMOSession() {
  const $auth = useAuthStore()
  const $session = useSessionStore()
  const $app = useAppStore()
  const $panels = usePanelsStore()
  const router = useRouter()

  const { $api, $SE } = useNuxtApp()

  const { isSessionEnvName, env } = useEnvironment()

  const ss = computed(() => $app.sessionState)

  const uiAvailable = computed(() => $app.initialised)

  function initSession(sessionID?: string) {
    return new Promise<SessionInitPayload>(async (resolve, reject) => {
      try {
        if (!sessionID) {
          const cookieSessionID = useCookie('session_id')
          if (cookieSessionID.value)
            sessionID = cookieSessionID.value
        }

        if (sessionID) {
          await $api.session.initSession(sessionID).then((session: SessionInitPayload) => {
            if (DEBUG_API)
              console.log('$api.session.initSession response: ', 'sessionID: ', sessionID, 'session: ', session)

            $app.sessionState = session.sessionState

            $panels.initialisePanels(session.focus)
            $session.initSession(session)

            resolve(session)
            // resolve(data)
          }).catch((error: any) => {
            $SE(error, `$api.session.init error - ${error.message}`)
            reject(error)
          })
        }
        else {
          console.error('There is no session ID saved in cookies - Logout')
          $session.logout()
        }
      }
      catch (error: any) {
        console.error('Session could not be refreshed - logout', error.message)
        $auth.logout()
        reject(error)
      }
    })
  }

  function initAuth() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await $api.auth.refresh().then((authData: AuthResponse) => {
          if (DEBUG_API)
            console.log('$api.auth.refresh response: ', 'authData: ', authData)

          if (authData.success) {
            $auth.init(authData)
            resolve()
          }
          else {
            $auth.logout()
            // reject(new Error('API: auth.login failed'))
          }
        }).catch((error: any) => {
          console.log('The auth refresh function failed', error)
          //  $auth.logout()
          $SE(error, `initAuth() $api.auth.login error - ${error.message}`)
          reject(error)
        })
      }
      catch (error) {
        reject(error)
      }
    })
  }

  function initValues() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const { $SE } = useNuxtApp()
        await $api.user.initUserValues($auth.user.id).then((response: UserValuesResponse) => {

          if (DEBUG_API)
            console.log('$api.user.initUserValues response: ', 'values: ', response)
          if (KIOE(response, 'values') && AEL(response.values)) {
            const $values = useValuesStore()
            $session.initValues(response.values)
            $values.initValues(response.values)
            resolve()
          } else {
            console.log("VALUEs NOT LOAD")
          }
        }).catch((error: any) => {
          console.log('init Values error: ', error)
          $SE(error, `initValues() $api.auth.initUserValues error - ${error.message}`)
          reject(error)
        })
      }
      catch (error) {
        reject(error)
      }
    })
  }

  onBeforeMount(() => {
    // resetModels()
  })
  onMounted(async () => {
    if (!$app.initialised) {
      try {
        await initAuth()
        await initValues()

        if (isSessionEnvName.value) {
          await initSession().then((session) => {
            $app.initialised = true

            if (['session-in-progress', 'session-complete-consideration', 'session-ready-to-start'].includes(session.sessionState))
              router.push('/session/overview')

            else if (session.sessionState === 'session-entering-tais')
              router.push('/session/tais')
          }).catch((error: any) => {
            console.log('Error initialising session from session id cookie', error.message)
            $session.logout()
          })
        }
        else {
          //
          if (env.value === 'dashboard' || env.value === 'create') {
            const $userrecord = useUserRecordStore()
            const $userstate = useUserStateStore()

            await $userrecord.initUserRecord().then(() => {
              // success
            }).catch((error: any) => {
              console.log('Error initialising user record', error.message)
              $session.logout()
            })

            await $userstate.initUserState().then(() => {
              $app.initialised = true
            }).catch((error: any) => {
              console.log('Error initialising user state', error.message)
              $session.logout()
            })
          }

          else {
            router.push('/')
            $app.initialised = true
          }
        }
      }
      catch (error: any) {
        $auth.logout()
      }
    }
  })

  return {
    $app,
    initSession,
    uiAvailable,
    ss,
  }
}
