import { defineStore } from 'pinia'

import { AEL } from '@/utility/utility'

interface ValuesRootState {
  values: string[]
}

export const useValuesStore = defineStore({
  id: 'valuesstore',

  state: (): ValuesRootState => ({
    values: [],
  }),

  actions: {
    initValues(values: string[]) {
      if (AEL(values))
        this.values = values
      else
        this.values = []
    },
  },

  getters: {

  },
})
