import { defineStore } from 'pinia'

import { useAuthStore } from '@/stores/auth'

import type { UserState } from '@/types/api/user'

interface UserStateRootState {
  initialised: boolean
  state: UserState
}

export const useUserStateStore = defineStore({
  id: 'userState',

  state: (): UserStateRootState => ({
    initialised: false,
    state: {
      taisFirstTime: true,
      taisChecklistComplete: false,
    },
  }),

  actions: {
    updateInitialised(initValue: boolean) {
      this.initialised = initValue
    },

    initUserState(stateKeysObj?: UserState) {
      return new Promise<void>(async (resolve, reject) => {
        if (stateKeysObj) {
          this.state = stateKeysObj
          this.initialised = true
          resolve()
        }
        else {
          const { $api, $SE } = useNuxtApp()
          const $auth = useAuthStore()
          await $api.user.initUserState($auth.user.id).then((state: UserState) => {
            this.state = state
            this.initialised = true
            resolve()
          }).catch((error: any) => {
            $SE(error, `$api.user.initUserState state - ${error.message}`)
            reject(error)
          })
        }
      })
    },

    async updateUserState(key: keyof UserState, value: boolean) {
      const { $api, $SE } = useNuxtApp()
      const $auth = useAuthStore()

      if (key) {
        this.state[key] = value
        await $api.user.updateUserState($auth.user.id, {
          state_key: key,
          state_value: value,
        }).catch((error: any) => {
          $SE(error, `$api.session.addEntry error - ${error.message}`)
        })
      }
    },
  },

  getters: {
    // getInitialised: state => state.initialised,
    taisAssistMode: state => state.state.taisFirstTime,
  },
})
